import { ChainId } from '@pancakeswap/sdk'

export const GRAPH_API_PROFILE = process.env.NEXT_PUBLIC_API_PROFILE
 export const GRAPH_API_PREDICTION_CRO = ''
export const GRAPH_API_PREDICTION_CCC = ''

export const GRAPH_API_LOTTERY = ''
export const SNAPSHOT_BASE_URL = process.env.NEXT_PUBLIC_SNAPSHOT_BASE_URL
export const API_PROFILE = ''
export const API_NFT = ''
export const SNAPSHOT_API = `${SNAPSHOT_BASE_URL}/graphql`
export const SNAPSHOT_HUB_API = `${SNAPSHOT_BASE_URL}/api/message`
// export const GRAPH_API_POTTERY = '/'

/**
 * V1 will be deprecated but is still used to claim old rounds
 */
export const GRAPH_API_PREDICTION_V1 = ''

export const INFO_CLIENT = process.env.NEXT_PUBLIC_INFO_CLIENT
export const INFO_CLIENT_CRONOS = process.env.NEXT_PUBLIC_INFO_CLIENT
export const INFO_CLIENT_ETH = process.env.NEXT_PUBLIC_INFO_CLIENT


export const BLOCKS_CLIENT_CRONOS = 'https://graph.cronoslabs.com/subgraphs/name/vvs/blocks/graphql'
export const BLOCKS_CLIENT = 'https://graph.cronoslabs.com/subgraphs/name/vvs/blocks/graphql'
export const BLOCKS_CLIENT_ETH = 'https://graph.cronoslabs.com/subgraphs/name/vvs/blocks/graphql'

 export const STABLESWAP_SUBGRAPH_CLIENT = ''
export const GRAPH_API_NFTMARKET = 'NEXT_PUBLIC_GRAPH_API_NFTMARKET'
export const GRAPH_HEALTH = 'https://api.thegraph.com/index-node/graphql'

export const TC_MOBOX_SUBGRAPH = '/'
export const TC_MOD_SUBGRAPH = '/'

export const FARM_API = 'https://farms-dev.cronoscats.workers.dev'

export const BIT_QUERY = 'https://graphql.bitquery.io'

export const ACCESS_RISK_API = '/api/risk'

export const CELER_API = 'https://api.celerscan.com/scan'

export const INFO_CLIENT_WITH_CHAIN = {
  [ChainId.CRONOS]: INFO_CLIENT_CRONOS,
}

export const BLOCKS_CLIENT_WITH_CHAIN = {
  [ChainId.CRONOS]: BLOCKS_CLIENT_CRONOS,
}
