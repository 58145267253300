import { ChainId } from '@pancakeswap/sdk'
import FarmsCronosPriceHelper from './25'

export const getFarmsPriceHelperLpFiles = (chainId: ChainId) => {
  switch (chainId) {
      case ChainId.CRONOS:
      return FarmsCronosPriceHelper
    default:
      return []
  }
}
