// @ts-nocheck
import { ChainId } from '@pancakeswap/sdk'
import { useActiveIfoWithBlocks } from 'hooks/useActiveIfoWithBlocks'
import { useUserCctLockStatus } from 'hooks/useUserCctLockStatus'
import { useMemo } from 'react'

import { useCompetitionStatus } from './useCompetitionStatus'

import { useVotingStatus } from './useVotingStatus'

export const useMenuItemsStatus = (): Record<string, string> => {
  const competitionStatus = useCompetitionStatus()

  const votingStatus = useVotingStatus()
  const isUserLocked = useUserCctLockStatus()

  return useMemo(() => {
    return {
      '/competition': competitionStatus,

      ...(votingStatus && {
        '/voting': votingStatus,
      }),
      ...(isUserLocked && {
        '/pools': 'lock_end',
      }),
    }
  }, [competitionStatus, votingStatus, isUserLocked])
}

