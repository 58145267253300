import { StaticJsonRpcProvider } from '@ethersproject/providers'

export const BSC_PROD_NODE = process.env.NEXT_PUBLIC_NODE_PRODUCTION || 'https://rpc.vvs.finance'
export const CRONOS_PROD_NODE = process.env.NEXT_PUBLIC_NODE_PRODUCTION || 'https://rpc.vvs.finance'
export const bscRpcProvider = new StaticJsonRpcProvider(BSC_PROD_NODE)
export const croRpcProvider = new StaticJsonRpcProvider(CRONOS_PROD_NODE)

export const simpleRpcProvider = new StaticJsonRpcProvider(CRONOS_PROD_NODE)

export default null
