import { ChainId, ERC20Token } from '@pancakeswap/sdk'


export const CCC_MAINET = new ERC20Token(
  ChainId.CRONOS,
  '0xb9A06cC294dA2B263aaf9E3E24194968b3f867eE',
  18,
  'CCC',
  'Cronos Cats Club',
  'https://cronoscats.club/',
)

export const PNDM_MAINET = new ERC20Token(
  ChainId.CRONOS,
  '0xE7e7579965050CB553F2b02595aec77F3B885077',
  18,
  'PNDM',
  'Pandamonium',
  'https://cronoscats.club/',
)

export const WCRO = new ERC20Token(
  ChainId.CRONOS,
  '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
  18,
  'WCRO',
  'Wrapped Cro',
  'https://cronos.org/',
)


export const USDC_CRONOS = new ERC20Token(
  ChainId.CRONOS,
  '0xc21223249CA28397B4B6541dfFaEcC539BfF0c59',
  6,
  'USDC',
  'USD Coin (PoS)',
  'https://www.centre.io/usdc',
)

export const USDT_CRONOS = new ERC20Token(
  ChainId.CRONOS,
  '0x66e428c3f67a68878562e79A0234c1F83c208770',
  6,
  'USDT',
  'Tether USDT',
  'https://tether.to/',
)

export const ETH_CRONOS = new ERC20Token(
  ChainId.CRONOS,
  '0xe44Fd7fCb2b1581822D0c862B68222998a0c299a',
  18,
  'ETH',
  'Ether',
  'https://www.weth.io',
)


export const CCC = {
  [ChainId.CRONOS]: CCC_MAINET,
}

export const USDC = {
  [ChainId.CRONOS]: USDC_CRONOS,
}

export const USDT = {
  [ChainId.CRONOS]: USDT_CRONOS,
}

export const WBTC_CRONOS = new ERC20Token(
  ChainId.CRONOS,
  '0x062E66477Faf219F25D27dCED647BF57C3107d52',
  8,
  'WBTC',
  'Wraped Bitcoin',
)

