import merge from 'lodash/merge'
// import teamsList from 'config/constants/teams'
import { getProfileContract } from 'utils/contractHelpers'
import { Team } from 'config/constants/types'
import { multicallv2 } from 'utils/multicall'
import { TeamsById } from 'state/types'
import profileABI from 'config/abi/cronoscatsProfile.json'
import { getCctcronoscatsProfileAddress } from 'utils/addressHelpers'
import fromPairs from 'lodash/fromPairs'

const profileContract = getProfileContract()

