import { cronosTokens } from '@pancakeswap/tokens'
import { Token, ChainId } from '@pancakeswap/sdk'
import { Memesale } from './types'

export const cctUsdcLPToken = new Token(
  ChainId.CRONOS,
  '0xC2f155fa7522Be94DdFa5c5f100083D3C2fc6Abf',
  18,
  'CCC-USDT LP',
)

const memesales: Memesale[] = [
  {
    id: 'PNDM',
    publicSaleAddress: '0xcEE88b5E5e08C110a489F95F2Ca5850dC33Bc6c4',
    whitelistSaleAddress: '0x6a0Dba35E68964D3eb482b08366c0241c6Ccf292',
    isActive: true,
    name: 'Pandamonium Token',
    poolWhitelist: {
      saleAmount: '116,666,666,666 PNDM',
      raiseAmount: '24,000 WCRO',
      cctToBurn: '0% of PNDM raised',
      distributionRatio: 0.3,
    },
    poolPublic: {
      saleAmount: '272,222,222,221 PNDM',
      raiseAmount: '100,000 WCRO',
      cctToBurn: '0% of PNDM raised',
      distributionRatio: 0.7,
    },
    description:
      'Cronos Cats Club',
    currency: cronosTokens.crolaunch,
    token: cronosTokens.pndm,
    startTimestamp: 1711410572,
    endTimestamp: 1712511729,
    campaignId: '1',
    articleUrl: 'https://cronoscats.medium.com/',
    tokenOfferingPrice: 0.000000367,
    tokenWhitelistOfferingPrice: 0.000000205,
    projectSite: 'https://cronoscats.club/',
    version: 1,
    loadMemesaleData: true,
   
  },
  
]

export default memesales
