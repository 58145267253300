import { getFullDecimalMultiplier } from '@pancakeswap/utils/getFullDecimalMultiplier'
import { BIG_TEN } from 'utils/bigNumber'

export const BSC_BLOCK_TIME = 2.3
export const CRONOS_BLOCK_TIME = 6

// CCC_PER_BLOCK details
// 40 CCC is minted per block
// 20 CCC per block is sent to Burn pool (A farm just for burning cake)
// 10 CCC per block goes to CCC syrup pool
// 9 CCC per block goes to Yield farms and lottery
// CCC_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CCC/Block in src/views/Home/components/CctDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const CCC_PER_BLOCK = 0.4 // 40 
export const BLOCKS_PER_DAY = (60 / CRONOS_BLOCK_TIME) * 60 * 24
export const BLOCKS_PER_YEAR = BLOCKS_PER_DAY * 365 // 10512000
export const CCC_PER_YEAR = CCC_PER_BLOCK * BLOCKS_PER_YEAR
export const BASE_URL = '/#'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const DEFAULT_TOKEN_DECIMAL = getFullDecimalMultiplier(18)
export const DEFAULT_TOKEN_6_DECIMAL = BIG_TEN.pow(6)
export const DEFAULT_GAS_LIMIT = 600000
export const BOOSTED_FARM_GAS_LIMIT = 600000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
