// @ts-nocheck
import type { Signer } from '@ethersproject/abstract-signer'
import type { Provider } from '@ethersproject/providers'
import { provider } from 'utils/wagmi'
import { Contract } from '@ethersproject/contracts'
import poolsConfig from 'config/constants/pools'
import { PoolCategory } from 'config/constants/types'
import { CCC } from '@pancakeswap/tokens'

// Addresses
import {
  getAddress,
  getCctcronoscatsProfileAddress,
  getCronoscatsMooreAddress,
  getCctcronoscatsFactoryAddress,
  // getBunnySpecialAddress,
  getLotteryV2Address,
  getMasterChefAddress,
  getMasterChefV1Address,
  getPointCenterIfoAddress,
  getClaimRefundAddress,
  getTradingCompetitionAddressEaster,
  getEasterNftAddress,
  getCctVaultAddress,
  getMulticallAddress,
  getBunnySpecialCctVaultAddress,
  getBunnySpecialPredictionAddress,
  getBunnySpecialLotteryAddress,
  getFarmAuctionAddress,
  getAnniversaryAchievement,
  getNftMarketAddress,
  getNftSaleAddress,
  getPancakeSquadAddress,
  getTradingCompetitionAddressFanToken,
  getTradingCompetitionAddressMobox,
  getTradingCompetitionAddressMoD,
  getBunnySpecialXmasAddress,
  getICctAddress,
  // getPotteryDrawAddress,
  getCctFlexibleSideVaultAddress,
  getPredictionsV1Address,
  getBCctFarmBoosterAddress,
  getBCctFarmBoosterProxyFactoryAddress,
  getNonBscVaultAddress,
  getCrossFarmingSenderAddress,
  getCrossFarmingReceiverAddress,

  getReferralAddress,
  getTokensLockAddress,


  
} from 'utils/addressHelpers'

// ABI


import profileABI from 'config/abi/cronoscatsProfile.json'
import cronoscatsMooreAbi from 'config/abi/cronoscatsMoore.json'
import cronoscatsFactoryAbi from 'config/abi/cronoscatsFactory.json'
import bunnySpecialAbi from 'config/abi/bunnySpecial.json'
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import cctAbi from 'config/abi/cct.json'
import ifoV1Abi from 'config/abi/ifoV1.json'
import ifoV2Abi from 'config/abi/ifoV2.json'
import ifoV3Abi from 'config/abi/ifoV3.json'
import memesaleV1Abi from 'config/abi/memesaleV1.json'
import memesaleV2Abi from 'config/abi/memesaleV2.json'
import memesaleV3Abi from 'config/abi/memesaleV3.json'
import pointCenterIfo from 'config/abi/pointCenterIfo.json'
import lotteryV2Abi from 'config/abi/lotteryV2.json'
import masterChef from 'config/abi/masterchef.json'
import masterChefV1 from 'config/abi/masterchefV1.json'
import sousChef from 'config/abi/sousChef.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import sousChefCro from 'config/abi/sousChefCro.json'
import claimRefundAbi from 'config/abi/claimRefund.json'
import tradingCompetitionEasterAbi from 'config/abi/tradingCompetitionEaster.json'
import tradingCompetitionFanTokenAbi from 'config/abi/tradingCompetitionFanToken.json'
import tradingCompetitionMoboxAbi from 'config/abi/tradingCompetitionMobox.json'
import tradingCompetitionMoDAbi from 'config/abi/tradingCompetitionMoD.json'
import easterNftAbi from 'config/abi/easterNft.json'
import cctVaultV2Abi from 'config/abi/cctVaultV2.json'
import cctFlexibleSideVaultV2Abi from 'config/abi/cctFlexibleSideVaultV2.json'
import predictionsAbi from 'config/abi/predictions.json'
import predictionsV1Abi from 'config/abi/predictionsV1.json'
import chainlinkOracleAbi from 'config/abi/chainlinkOracle.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import bunnySpecialCctVaultAbi from 'config/abi/bunnySpecialCctVault.json'
import bunnySpecialPredictionAbi from 'config/abi/bunnySpecialPrediction.json'
import bunnySpecialLotteryAbi from 'config/abi/bunnySpecialLottery.json'
import bunnySpecialXmasAbi from 'config/abi/bunnySpecialXmas.json'
import farmAuctionAbi from 'config/abi/farmAuction.json'
import anniversaryAchievementAbi from 'config/abi/anniversaryAchievement.json'
import nftMarketAbi from 'config/abi/nftMarket.json'
import nftSaleAbi from 'config/abi/nftSale.json'
import pancakeSquadAbi from 'config/abi/pancakeSquad.json'
import erc721CollectionAbi from 'config/abi/erc721collection.json'
// import potteryVaultAbi from 'config/abi/potteryVaultAbi.json'
// import potteryDrawAbi from 'config/abi/potteryDrawAbi.json'
import iCctAbi from 'config/abi/iCct.json'
import cakePredictionsAbi from 'config/abi/cakePredictions.json'
import bCctFarmBoosterAbi from 'config/abi/bCctFarmBooster.json'
import bCctFarmBoosterProxyFactoryAbi from 'config/abi/bCctFarmBoosterProxyFactory.json'
import bCctProxyAbi from 'config/abi/bCctProxy.json'
import nonBscVault from 'config/abi/nonBscVault.json'
import crossFarmingSenderAbi from 'config/abi/crossFarmingSender.json'
import crossFarmingReceiverAbi from 'config/abi/crossFarmingReceiver.json'
import crossFarmingProxyAbi from 'config/abi/crossFarmingProxy.json'
import tokensLockAbi from 'config/abi/tokensLock.json'


// Types
import type {
  ChainlinkOracle,
  FarmAuction,
  Predictions,
  AnniversaryAchievement,
  IfoV1,
  IfoV2,
  MemesaleV1,
  MemesaleV2,

  Referral,
  TokensLock,


  Erc20,
  Erc721,
  Cct,
  CctcronoscatsFactory,
  CronoscatsMoore,
  CctcronoscatsProfile,
  LotteryV2,
  Masterchef,
  MasterchefV1,
  SousChef,
  SousChefV2,
  BunnySpecial,
  LpToken,
  ClaimRefund,
  TradingCompetitionEaster,
  TradingCompetitionFanToken,
  EasterNft,
  Multicall,
  BunnySpecialCctVault,
  BunnySpecialPrediction,
  BunnySpecialLottery,
  NftMarket,
  NftSale,
  PancakeSquad,
  Erc721collection,
  PointCenterIfo,
  CctVaultV2,
  CctFlexibleSideVaultV2,
  TradingCompetitionMobox,
  ICct,
  TradingCompetitionMoD,
  // PotteryVaultAbi,
  // PotteryDrawAbi,
  PredictionsV1,
  BCctFarmBooster,
  BCctFarmBoosterProxyFactory,
  BCctProxy,
  NonBscVault,
  CrossFarmingSender,
  CrossFarmingReceiver,
  CrossFarmingProxy,
} from 'config/abi/types'
import { ChainId } from '@pancakeswap/sdk'

export const getContract = ({
  abi,
  address,
  chainId = ChainId.CRONOS,
  signer,
}: {
  abi: any
  address: string
  chainId?: ChainId
  signer?: Signer | Provider
}) => {
  const signerOrProvider = signer ?? provider({ chainId })
  return new Contract(address, abi, signerOrProvider)
}

export const getReferralContract = (chainId: number, signer?: Signer | Provider) => {
  return getContract({ abi: referralAbi, address: getReferralAddress(chainId), chainId, signer }) as Referral
}

export const getTokensLockContract = (chainId: number, signer?: Signer | Provider) => {
  return getContract({ abi: tokensLockAbi, address: getTokensLockAddress(chainId), signer }) as TokensLock
}

export const getBep20Contract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: bep20Abi, address, signer }) as Erc20
}
export const getErc721Contract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: erc721Abi, address, signer }) as Erc721
}
export const getLpContract = (address: string, chainId?: number, signer?: Signer | Provider) => {
  return getContract({ abi: lpTokenAbi, address, signer, chainId }) as LpToken
}

export const getIfoV1Contract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: ifoV1Abi, address, signer }) as IfoV1
}
export const getIfoV2Contract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: ifoV2Abi, address, signer }) as IfoV2
}
export const getIfoV3Contract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: ifoV3Abi, address, signer })
}

export const getMemesaleV1Contract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: memesaleV1Abi, address, signer }) as MemesaleV1
}
export const getMemesaleV2Contract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: memesaleV2Abi, address, signer }) as MemesaleV2
}
export const getMemesaleV3Contract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: memesaleV3Abi, address, signer })
}



export const getSouschefContract = (id: number, signer?: Signer | Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  const abi = config.poolCategory === PoolCategory.CRONOS ? sousChefCro : sousChef
  return getContract({ abi, address: getAddress(config.contractAddress), signer }) as SousChef
}
export const getSouschefV2Contract = (id: number, signer?: Signer | Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  return getContract({ abi: sousChefV2, address: getAddress(config.contractAddress), signer }) as SousChefV2
}

export const getPointCenterIfoContract = (signer?: Signer | Provider) => {
  return getContract({ abi: pointCenterIfo, address: getPointCenterIfoAddress(), signer }) as PointCenterIfo
}
export const getCctContract = (signer?: Signer | Provider, chainId?: number) => {
  return getContract({
    abi: cctAbi,
    address: chainId ? CCC[chainId].address : CCC[ChainId.CRONOS].address,
    signer,
    chainId: 25,
  }) as Cct
}
export const getProfileContract = (signer?: Signer | Provider) => {
  return getContract({ abi: profileABI, address: getCctcronoscatsProfileAddress(), signer }) as CctcronoscatsProfile
}
export const getCronoscatsMooreContract = (signer?: Signer | Provider) => {
  return getContract({ abi: cronoscatsMooreAbi, address: getCronoscatsMooreAddress(), signer }) as CronoscatsMoore
}
export const getCctcronoscatsFactoryContract = (signer?: Signer | Provider) => {
  return getContract({ abi: cronoscatsFactoryAbi, address: getCctcronoscatsFactoryAddress(), signer }) as CctcronoscatsFactory
}
// export const getBunnySpecialContract = (signer?: Signer | Provider) => {
 // return getContract({ abi: bunnySpecialAbi, address: getBunnySpecialAddress(), signer }) as BunnySpecial
// }
export const getLotteryV2Contract = (signer?: Signer | Provider) => {
  return getContract({ abi: lotteryV2Abi, address: getLotteryV2Address(), signer }) as LotteryV2
}
export const getMasterchefContract = (signer?: Signer | Provider, chainId?: number) => {
  return getContract({ abi: masterChef, address: getMasterChefAddress(chainId), signer }) as Masterchef
}
export const getMasterchefV1Contract = (signer?: Signer | Provider) => {
  return getContract({ abi: masterChefV1, address: getMasterChefV1Address(), signer }) as MasterchefV1
}
export const getClaimRefundContract = (signer?: Signer | Provider) => {
  return getContract({ abi: claimRefundAbi, address: getClaimRefundAddress(), signer }) as ClaimRefund
}
export const getTradingCompetitionContractEaster = (signer?: Signer | Provider) => {
  return getContract({
    abi: tradingCompetitionEasterAbi,
    address: getTradingCompetitionAddressEaster(),
    signer,
  }) as TradingCompetitionEaster
}

export const getTradingCompetitionContractFanToken = (signer?: Signer | Provider) => {
  return getContract({
    abi: tradingCompetitionFanTokenAbi,
    address: getTradingCompetitionAddressFanToken(),
    signer,
  }) as TradingCompetitionFanToken
}
export const getTradingCompetitionContractMobox = (signer?: Signer | Provider) => {
  return getContract({
    abi: tradingCompetitionMoboxAbi,
    address: getTradingCompetitionAddressMobox(),
    signer,
  }) as TradingCompetitionMobox
}

export const getTradingCompetitionContractMoD = (signer?: Signer | Provider) => {
  return getContract({
    abi: tradingCompetitionMoDAbi,
    address: getTradingCompetitionAddressMoD(),
    signer,
  }) as TradingCompetitionMoD
}

export const getEasterNftContract = (signer?: Signer | Provider) => {
  return getContract({ abi: easterNftAbi, address: getEasterNftAddress(), signer }) as EasterNft
}
export const getCctVaultV2Contract = (signer?: Signer | Provider) => {
  return getContract({ abi: cctVaultV2Abi, address: getCctVaultAddress(), signer }) as CctVaultV2
}

export const getCctFlexibleSideVaultV2Contract = (signer?: Signer | Provider) => {
  return getContract({
    abi: cctFlexibleSideVaultV2Abi,
    address: getCctFlexibleSideVaultAddress(),
    signer,
  }) as CctFlexibleSideVaultV2
}

export const getPredictionsContract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: predictionsAbi, address, signer }) as Predictions
}

export const getPredictionsV1Contract = (signer?: Signer | Provider) => {
  return getContract({ abi: predictionsV1Abi, address: getPredictionsV1Address(), signer }) as PredictionsV1
}

export const getCctPredictionsContract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: cakePredictionsAbi, address, signer }) as Predictions
}

export const getChainlinkOracleContract = (address: string, signer?: Signer | Provider, chainId?: number) => {
  return getContract({ abi: chainlinkOracleAbi, address, signer, chainId }) as ChainlinkOracle
}
export const getMulticallContract = (chainId: ChainId) => {
  return getContract({ abi: MultiCallAbi, address: getMulticallAddress(chainId), chainId }) as Multicall
}
export const getBunnySpecialCctVaultContract = (signer?: Signer | Provider) => {
  return getContract({
    abi: bunnySpecialCctVaultAbi,
    address: getBunnySpecialCctVaultAddress(),
    signer,
  }) as BunnySpecialCctVault
}
export const getBunnySpecialPredictionContract = (signer?: Signer | Provider) => {
  return getContract({
    abi: bunnySpecialPredictionAbi,
    address: getBunnySpecialPredictionAddress(),
    signer,
  }) as BunnySpecialPrediction
}
export const getBunnySpecialLotteryContract = (signer?: Signer | Provider) => {
  return getContract({
    abi: bunnySpecialLotteryAbi,
    address: getBunnySpecialLotteryAddress(),
    signer,
  }) as BunnySpecialLottery
}
export const getBunnySpecialXmasContract = (signer?: Signer | Provider) => {
  return getContract({ abi: bunnySpecialXmasAbi, address: getBunnySpecialXmasAddress(), signer })
}
export const getFarmAuctionContract = (signer?: Signer | Provider) => {
  return getContract({ abi: farmAuctionAbi, address: getFarmAuctionAddress(), signer }) as unknown as FarmAuction
}
export const getAnniversaryAchievementContract = (signer?: Signer | Provider) => {
  return getContract({
    abi: anniversaryAchievementAbi,
    address: getAnniversaryAchievement(),
    signer,
  }) as AnniversaryAchievement
}

export const getNftMarketContract = (signer?: Signer | Provider) => {
  return getContract({ abi: nftMarketAbi, address: getNftMarketAddress(), signer }) as NftMarket
}
export const getNftSaleContract = (signer?: Signer | Provider) => {
  return getContract({ abi: nftSaleAbi, address: getNftSaleAddress(), signer }) as NftSale
}
export const getPancakeSquadContract = (signer?: Signer | Provider) => {
  return getContract({ abi: pancakeSquadAbi, address: getPancakeSquadAddress(), signer }) as PancakeSquad
}
export const getErc721CollectionContract = (signer?: Signer | Provider, address?: string) => {
  return getContract({ abi: erc721CollectionAbi, address, signer }) as Erc721collection
}

// export const getPotteryVaultContract = (address: string, signer?: Signer | Provider) => {
//  return getContract({ abi: potteryVaultAbi, address, signer }) as PotteryVaultAbi
// }

// export const getPotteryDrawContract = (signer?: Signer | Provider) => {
//  return getContract({ abi: potteryDrawAbi, address: getPotteryDrawAddress(), signer }) as PotteryDrawAbi
// }

export const getIfoCreditAddressContract = (signer?: Signer | Provider) => {
  return getContract({ abi: iCctAbi, address: getICctAddress(), signer }) as ICct
}

export const getMemesaleCreditAddressContract = (signer?: Signer | Provider) => {
  return getContract({ abi: iCctAbi, address: getICctAddress(), signer }) as ICct
}

export const getBCctFarmBoosterContract = (signer?: Signer | Provider) => {
  return getContract({ abi: bCctFarmBoosterAbi, address: getBCctFarmBoosterAddress(), signer }) as BCctFarmBooster
}

export const getBCctFarmBoosterProxyFactoryContract = (signer?: Signer | Provider) => {
  return getContract({
    abi: bCctFarmBoosterProxyFactoryAbi,
    address: getBCctFarmBoosterProxyFactoryAddress(),
    signer,
  }) as BCctFarmBoosterProxyFactory
}

export const getBCctProxyContract = (proxyContractAddress: string, signer?: Signer | Provider) => {
  return getContract({ abi: bCctProxyAbi, address: proxyContractAddress, signer }) as BCctProxy
}

export const getNonBscVaultContract = (signer?: Signer | Provider, chainId?: number) => {
  return getContract({ abi: nonBscVault, address: getNonBscVaultAddress(chainId), chainId, signer }) as NonBscVault
}

export const getCrossFarmingSenderContract = (signer?: Signer | Provider, chainId?: number) => {
  return getContract({
    abi: crossFarmingSenderAbi,
    address: getCrossFarmingSenderAddress(chainId),
    chainId,
    signer,
  }) as CrossFarmingSender
}

export const getCrossFarmingReceiverContract = (signer?: Signer | Provider, chainId?: number) => {
  return getContract({
    abi: crossFarmingReceiverAbi,
    address: getCrossFarmingReceiverAddress(chainId),
    chainId,
    signer,
  }) as CrossFarmingReceiver
}

export const getCrossFarmingProxyContract = (
  proxyContractAddress: string,
  signer?: Signer | Provider,
  chainId?: number,
) => {
  return getContract({ abi: crossFarmingProxyAbi, address: proxyContractAddress, chainId, signer }) as CrossFarmingProxy
}
