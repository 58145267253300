import { cronosTokens } from '@pancakeswap/tokens'
import { Token, ChainId } from '@pancakeswap/sdk'
import { Ifo } from './types'

export const cctUsdcLPToken = new Token(
  ChainId.CRONOS,
  '0xC2f155fa7522Be94DdFa5c5f100083D3C2fc6Abf',
  18,
  'CCC-USDT LP',
)

const ifos: Ifo[] = [
  {
    id: 'CCC',
    publicSaleAddress: '0xcEBc982Cca20b868234D183fD0A75d9A9e49Ed62',
    whitelistSaleAddress: '0xbbA8f997dd19fe903780961133e15E1d63a24328',
    isActive: true,
    name: 'Cronos Cats Club',
    poolWhitelist: {
      saleAmount: '62,500,000 CCC',
      raiseAmount: '50,000 WCRO',
      cctToBurn: '0% of CCC raised',
      distributionRatio: 0.2,
    },
    poolPublic: {
      saleAmount: '200,000,000 CCC',
      raiseAmount: '200,000 WCRO',
      cctToBurn: '0% of CCC raised',
      distributionRatio: 0.8,
    },
    description:
      'Cronos Cats Club',
    currency: cronosTokens.crolaunch,
    token: cronosTokens.ccc,
    startTimestamp: 1703343409,
    endTimestamp: 1712340653,
    campaignId: '1',
    articleUrl: 'https://cronoscats.medium.com/',
    tokenOfferingPrice: 0.001,
    tokenWhitelistOfferingPrice: 0.0008,
    projectSite: 'https://cronoscats.club/',
    version: 1,
    loadIfoData: true,
   
  },
  
]

export default ifos
