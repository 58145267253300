import BigNumber from 'bignumber.js'
import { multicallv2, multicallv3 } from 'utils/multicall'
import cctAbi from 'config/abi/cct.json'
import cctVaultAbi from 'config/abi/cctVaultV2.json'
import { getCctVaultAddress, getCctFlexibleSideVaultAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import { ChainId } from '@pancakeswap/sdk'
import { CCC } from '@pancakeswap/tokens'

const cctVaultV2 = getCctVaultAddress()
const cctFlexibleSideVaultV2 = getCctFlexibleSideVaultAddress()
export const fetchPublicVaultData = async (cctVaultAddress = cctVaultV2) => {
  try {
    const calls = ['getPricePerFullShare', 'totalShares', 'totalLockedAmount'].map((method) => ({
      abi: cctVaultAbi,
      address: cctVaultAddress,
      name: method,
    }))

    const cctBalanceOfCall = {
      abi: cctAbi,
      address: CCC[ChainId.CRONOS].address,
      name: 'balanceOf',
      params: [cctVaultV2],
    }

    const [[sharePrice], [shares], totalLockedAmount, [totalCctInVault]] = await multicallv3({
      calls: [...calls, cctBalanceOfCall],
      allowFailure: true,
    })

    const totalSharesAsBigNumber = shares ? new BigNumber(shares.toString()) : BIG_ZERO
    const totalLockedAmountAsBigNumber = totalLockedAmount ? new BigNumber(totalLockedAmount[0].toString()) : BIG_ZERO
    const sharePriceAsBigNumber = sharePrice ? new BigNumber(sharePrice.toString()) : BIG_ZERO
    return {
      totalShares: totalSharesAsBigNumber.toJSON(),
      totalLockedAmount: totalLockedAmountAsBigNumber.toJSON(),
      pricePerFullShare: sharePriceAsBigNumber.toJSON(),
      totalCctInVault: new BigNumber(totalCctInVault.toString()).toJSON(),
    }
  } catch (error) {
    return {
      totalShares: null,
      totalLockedAmount: null,
      pricePerFullShare: null,
      totalCctInVault: null,
    }
  }
}

export const fetchPublicFlexibleSideVaultData = async (cctVaultAddress = cctFlexibleSideVaultV2) => {
  try {
    const calls = ['getPricePerFullShare', 'totalShares'].map((method) => ({
      abi: cctVaultAbi,
      address: cctVaultAddress,
      name: method,
    }))

    const cctBalanceOfCall = {
      abi: cctAbi,
      address: CCC[ChainId.CRONOS].address,
      name: 'balanceOf',
      params: [cctVaultAddress],
    }

    const [[sharePrice], [shares], [totalCctInVault]] = await multicallv3({
      calls: [...calls, cctBalanceOfCall],
      allowFailure: true,
    })

    const totalSharesAsBigNumber = shares ? new BigNumber(shares.toString()) : BIG_ZERO
    const sharePriceAsBigNumber = sharePrice ? new BigNumber(sharePrice.toString()) : BIG_ZERO
    return {
      totalShares: totalSharesAsBigNumber.toJSON(),
      pricePerFullShare: sharePriceAsBigNumber.toJSON(),
      totalCctInVault: new BigNumber(totalCctInVault.toString()).toJSON(),
    }
  } catch (error) {
    return {
      totalShares: null,
      pricePerFullShare: null,
      totalCctInVault: null,
    }
  }
}

export const fetchVaultFees = async (cctVaultAddress = cctVaultV2) => {
  try {
    const calls = ['performanceFee', 'withdrawFee', 'withdrawFeePeriod'].map((method) => ({
      address: cctVaultAddress,
      name: method,
    }))

    const [[performanceFee], [withdrawalFee], [withdrawalFeePeriod]] = await multicallv2({ abi: cctVaultAbi, calls })

    return {
      performanceFee: performanceFee.toNumber(),
      withdrawalFee: withdrawalFee.toNumber(),
      withdrawalFeePeriod: withdrawalFeePeriod.toNumber(),
    }
  } catch (error) {
    return {
      performanceFee: null,
      withdrawalFee: null,
      withdrawalFeePeriod: null,
    }
  }
}

export default fetchPublicVaultData
