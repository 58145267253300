// export const CRONOSCATS_EXTENDED = 'https://tokens.cronoscats.club/cronoscats-extended.json'
export const COINGECKO = 'https://tokens.cronoscats.club/coingecko.json'
// export const COINGECKO_ETH = 'https://tokens.coingecko.com/uniswap/all.json'
// export const CMC = 'https://tokens.pancakeswap.finance/cmc.json'

// export const ETH_URLS = [COINGECKO_ETH]
export const BSC_URLS = [COINGECKO]

// List of official tokens list
export const OFFICIAL_LISTS = [COINGECKO]

export const UNSUPPORTED_LIST_URLS: string[] = []
export const WARNING_LIST_URLS: string[] = []

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  COINGECKO,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
  ...WARNING_LIST_URLS,
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = []
