import { ChainId } from '@pancakeswap/sdk'
import { Pool } from '@pancakeswap/uikit'
import addresses from 'config/constants/contracts'
import { VaultKey } from 'state/types'

export const getAddress = (address: Pool.Address, chainId?: number): string => {
  return address[chainId] ? address[chainId] : address[ChainId.CRONOS]
}

export const getMasterChefAddress = (chainId?: number) => {
  return getAddress(addresses.masterChef, chainId)
}
export const getMasterChefV1Address = () => {
  return getAddress(addresses.masterChefV1)
}
export const getMulticallAddress = (chainId?: number) => {
  return getAddress(addresses.multiCall, chainId)
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}
export const getCctcronoscatsProfileAddress = () => {
  return getAddress(addresses.cronoscatsProfile)
}
export const getCronoscatsMooreAddress = () => {
  return getAddress(addresses.cronoscatsMoore)
}
export const getCctcronoscatsFactoryAddress = () => {
  return getAddress(addresses.cronoscatsFactory)
}
export const getPredictionsV1Address = () => {
  return getAddress(addresses.predictionsV1)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}
export const getCronoscatsSpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddressEaster = () => {
  return getAddress(addresses.tradingCompetitionEaster)
}
export const getTradingCompetitionAddressFanToken = () => {
  return getAddress(addresses.tradingCompetitionFanToken)
}

export const getTradingCompetitionAddressMobox = () => {
  return getAddress(addresses.tradingCompetitionMobox)
}

export const getTradingCompetitionAddressMoD = () => {
  return getAddress(addresses.tradingCompetitionMoD)
}

export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}

export const getVaultPoolAddress = (vaultKey: VaultKey) => {
  if (!vaultKey) {
    return null
  }
  return getAddress(addresses[vaultKey])
}

export const getCctVaultAddress = () => {
  return getAddress(addresses.cctVault)
}

export const getCctFlexibleSideVaultAddress = () => {
  return getAddress(addresses.cctFlexibleSideVault)
}

export const getBunnySpecialCctVaultAddress = () => {
  return getAddress(addresses.bunnySpecialCctVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}
export const getBunnySpecialLotteryAddress = () => {
  return getAddress(addresses.bunnySpecialLottery)
}
export const getBunnySpecialXmasAddress = () => {
  return getAddress(addresses.bunnySpecialXmas)
}
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}
export const getAnniversaryAchievement = () => {
  return getAddress(addresses.AnniversaryAchievement)
}

export const getNftMarketAddress = () => {
  return getAddress(addresses.nftMarket)
}
export const getNftSaleAddress = () => {
  return getAddress(addresses.nftSale)
}
export const getPancakeSquadAddress = () => {
  return getAddress(addresses.pancakeSquad)
}
// export const getPotteryDrawAddress = () => {
 //  return getAddress(addresses.potteryDraw)
// }

export const getZapAddress = (chainId?: number) => {
  return getAddress(addresses.zap, chainId)
}
export const getICctAddress = () => {
  return getAddress(addresses.iCct)
}

export const getBCctFarmBoosterAddress = () => {
  return getAddress(addresses.bCctFarmBooster)
}

export const getBCctFarmBoosterProxyFactoryAddress = () => {
  return getAddress(addresses.bCctFarmBoosterProxyFactory)
}

export const getNonBscVaultAddress = (chainId?: number) => {
  return getAddress(addresses.nonBscVault, chainId)
}

export const getCrossFarmingSenderAddress = (chainId?: number) => {
  return getAddress(addresses.crossFarmingSender, chainId)
}

export const getCrossFarmingReceiverAddress = (chainId?: number) => {
  return getAddress(addresses.crossFarmingReceiver, chainId)
}


export const getReferralAddress = (chainId: number) => {
  return getAddress(addresses.referral, chainId)
}
export const getTokensLockAddress = (chainId: number) => {
  return getAddress(addresses.tokensLock, chainId)
}
