import { multicallAddresses } from '@pancakeswap/multicall'

export default {
  masterChef: {
    97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    56: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
    25: '0xC3a92F007E573D5Dc267E7D3CAb574da497b8685',
  },
  masterChefV1: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    25: '0x08c40f9C65FF4eDe91FD5d67Eff82365fD43B7D0',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
    25: ''
  },
  lotteryV2: {
    56: '',
    25: '0x1a17B4A67eB894E51bf9955AE627E5De5275c546', 
  },
  multiCall: multicallAddresses,
  cronoscatsProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    25: ''
 
  },
  cronoscatsMoore: {
   25: '', 
  },
  cronoscatsFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    25: ''
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
    25: ''
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    25: ''
   
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    25: ''
  },
  tradingCompetitionEaster: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    25: ''
  },
  tradingCompetitionFanToken: {
    56: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4', 
    97: '',
    25: ''
  },
  tradingCompetitionMobox: {
    56: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    97: '',
    25: ''
  },
  tradingCompetitionMoD: {
    56: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    97: '',
    25: ''
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    25: ''
  },
  cctVault: {
    56: '0x45c54210128a065de780C4B0Df3d16664f7f859e',
    25: '0x907A4F1F6c7c2123653B21e5f62300dF2e87E451',
    

  },
  cctFlexibleSideVault: {
    56: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    25: '0xd22Ae60956e3c780Ff638dc90c0303B5454ecA09',
    97: '',
  },
  predictionsCRO: {
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    97: '',
    25: ''
  },
  predictionsCCC: {
    56: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    97: '',
    25: ''
  },
  chainlinkOracleCRO: {
    56: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    97: '',
    25: ''
  },
  chainlinkOracleCCC: {
    56: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    97: '',
    25: ''
  },
  predictionsV1: {
    56: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    97: '',
    25: ''
  },
  bunnySpecialCctVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
    25: ''
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
    25: ''
  },
  bunnySpecialLottery: {
    56: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  bunnySpecialXmas: {
    56: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    97: '',
    25: ''
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    25: '0x69fA1Bac360DC1b75d1024F259B7236ae7e51559'
  },
  AnniversaryAchievement: {
    56: '0xF839286bD9D14b358496829F3BaB3145C16ad3C1',
    97: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    25: ''
  },
  nftMarket: {
    56: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    250: '0x05d161D26693Bc52eDdf3543eEe0627078D5C8c1', 
    25: ''
  },
  nftSale: {
    56: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    25: ''
  },
  pancakeSquad: {
    56: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    97: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    25: ''
  },
  // TODO: multi
  zap: {
    56: '0xD4c4a7C55c9f7B3c48bafb6E8643Ba79F42418dF',
    250: '0xbF22A74c1ed40Ba20779F9F40CcCB2c7a10AF63b', 
    25: ''
  },
  iCct: {
    56: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    25: '0x306A9fBAf0d3915452048A5582F01F6Fb4B29d9B',
    97: '',
  },
  bCctFarmBooster: {
    56: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    25: '0x60EA0e8a30622DaAF4C5CFabe292f2f12D3A023F',
    97: '',
  },
  bCctFarmBoosterProxyFactory: {
    56: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    25: '0x7EeacD994dF1b6Ca2F9d558838921e6d4c358149',
    97: '',
  },
  nonBscVault: {
    56: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841', 
    1: '0x2e71B2688019ebdFDdE5A45e6921aaebb15b25fb',
    5: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    25: ''
  },
  crossFarmingSender: {
    56: '0x327d26dE30f92600620A99043034e0A5FD9402C8', 
    1: '0x8EA90Ef07f37c77137453C7A1B72B7886d51eCFb',
    5: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    25: ''
  },
  crossFarmingReceiver: {
    56: '0x0726a8C8206b9eC0AfB788df5adb36a8AEDB13c2',
    97: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    25: ''
  },
  referral: {
    56: '',
    97: '',
    25: '',
  },
  tokensLock: {
    56: '',
    97: '',
    25: '0x17133513aE37EcE431152e441cc8e58d712B6564',
  }
}
