import { ChainId, JSBI, Percent, Token, WNATIVE } from '@pancakeswap/sdk'
import { BigNumber } from '@ethersproject/bignumber'
import {
  cronosTokens,
  USDC,
  USDT,
  WBTC_CRONOS,
} from '@pancakeswap/tokens'
import { ChainMap, ChainTokenList } from './types'

export const ROUTER_ADDRESS: ChainMap<string> = {

  [ChainId.CRONOS]: '0xD5e1B5cb004FcDAA7372B7AEb5DCcf3e6857Fe79',
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.CRONOS]: [
    cronosTokens.wcro,
    cronosTokens.ccc,
    cronosTokens.usdt,
    cronosTokens.usdc,
    cronosTokens.wbtc,
    cronosTokens.eth,
  ],

}

/**
 * Additional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {

}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WNATIVE[ChainId.BSC]]
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {

  [ChainId.CRONOS]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {

  [ChainId.CRONOS]: [USDT[ChainId.CRONOS], USDC[ChainId.CRONOS], WNATIVE[ChainId.CRONOS]],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {

  [ChainId.CRONOS]: [USDT[ChainId.CRONOS], WNATIVE[ChainId.CRONOS],],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.CRONOS]: [
    [cronosTokens.ccc, cronosTokens.wcro],
    [cronosTokens.usdt, cronosTokens.usdc],
    [cronosTokens.wbtc, cronosTokens.eth],
  ],
}

export const BIG_INT_ZERO = JSBI.BigInt(0)
export const BIG_INT_TEN = JSBI.BigInt(10)

// one basis point
export const BIPS_BASE = JSBI.BigInt(10000)
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much CRO so they end up with <.01
export const MIN_CRO: JSBI = JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(16)) // .01 CRO
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), BIPS_BASE)

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const BASE_FEE = new Percent(JSBI.BigInt(25), BIPS_BASE)
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

export const DEFAULT_INPUT_CURRENCY = 'CRO'

export const DEFAULT_OUTPUT_CURRENCY = '0xb9A06cC294dA2B263aaf9E3E24194968b3f867eE'

// Handler string is passed to Gelato to use PCS router
// export const GELATO_HANDLER = 'uniswap_stoplimit'
export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = BigNumber.from(50000000)

export const LIMIT_ORDERS_DOCS_URL = 'https://docs.cronoscats.club/'

export const EXCHANGE_PAGE_PATHS = ['/swap', '/limit-orders', 'liquidity', '/add', '/find', '/remove']
