import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  RocketIcon,
  MoreIcon,
  DropdownMenuItems,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { SUPPORT_ONLY_CRONOS} from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: 'Trade',
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      showItemsOnMobile: false,
      items: [
        {
          label: t('Swap'),
          href: '/swap',
        },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },

      ].map((item) => addMenuItemSupported(item, chainId)),
    },
   
   
    {
      label: 'IDOs',
      href: '/launchpad',
      icon: RocketIcon,
    //  fillIcon: RocketIcon,
      supportChainIds: SUPPORT_ONLY_CRONOS,
      items: [
        {
          label: 'CCC Launchpad',
          href: '/launchpad',
          image: '/images/cronoscats/check-your-network.png',
        },
        {
          label: 'Tokens Lock',
          href: '/launchpad/lock',
          image: '/images/cronoscats/check-your-network.png',
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: 'MEME Factory',
      href: '/memesale',
      icon: RocketIcon,
    //  fillIcon: RocketIcon,
      supportChainIds: SUPPORT_ONLY_CRONOS,
      items: [
        {
          label: 'Presale',
          href: '/memesale',
          image: '/images/cronoscats/check-your-network.png',
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
{
      label: 'NFTs',
      icon: NftIcon,
      href: 'https://cronoscats.club/',
      supportChainIds: SUPPORT_ONLY_CRONOS,
      type: DropdownMenuItemType.EXTERNAL_LINK,
      fillIcon: NftFillIcon,
      image: '/images/cronoscats/check-your-network.png',
      items: [
        {
          label: 'Mint Cronos Cats NFT',
          href: 'https://nft.cronoscats.club/mint',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: 'Mint Membership Pass NFT',
          href: 'https://cronoscats.club/nfts/collections/mint/0x9b389aeBC343C6B759E23334733F32D779595ea5',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: 'Mint Pandamonium NFT',
          href: 'https://cronoscats.club/nfts/collections/mint/0xF298a3e3ef422DA5aAb4A147De78D45fef49a26a',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: 'Stake NFTs',
          href: 'https://cronoscats.club/nft-staking',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
  
      ],
    },
    {
      label: '...',
      icon: MoreIcon,
      hideSubNav: true,
      items: [
        {
          label: 'Minted Network',
          href: 'https://minted.network/collections/cronos/0xa2ebd9d8a5829e64598fb6159d5a187c73a4029a',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: 'DEX',
          href: 'https://cronoscats.club',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },

      ].map((item) => addMenuItemSupported(item, chainId)),
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
