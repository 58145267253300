import { cronosTokens } from '@pancakeswap/tokens'
import { SerializedFarmConfig } from '@pancakeswap/farms'

const priceHelperLps: SerializedFarmConfig[] = [
  {
    pid: null,
    lpSymbol: 'WCRO-USDC LP',
    lpAddress: '0xe61Db569E231B3f5530168Aa2C9D50246525b6d6',
    quoteToken: cronosTokens.usdc,
    token: cronosTokens.wcro,
  },
].map((p) => ({ ...p, token: p.token.serialize, quoteToken: p.quoteToken.serialize }))

export default priceHelperLps