import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
<Svg width="17" height="17" viewBox="0 0 222 222" fill="none" {...props}>
<path d="M110.808 4.53064L202.367 57.6442V164.201L110.913 217.469L110.173 217.285L19.6326 164.201V57.6442L110.173 4.53064H110.808V4.53064ZM110.509 15.5544L29.2502 63.2142V158.631L110.5 206.281L120.425 200.498L192.75 158.602V63.2336L120.425 21.0953L110.509 15.5544V15.5544ZM41.8397 118.333L65.8934 100.218L87.1579 113.896V138.501L103.248 154.117L103.239 161.44L87.735 176.067H74.6454L41.8493 118.333H41.8397ZM117.848 161.459L117.819 154.097L133.851 138.501V113.896L154.885 100.044L178.891 118.343L146.258 175.903H133.332L117.848 161.459ZM95.054 113.896L87.2252 93.2535H133.755L126.08 113.896L128.35 136.971L110.394 137.01L92.6496 137.039L95.054 113.896V113.896ZM110.394 87.5963L66.1723 87.5769L74.3953 50.5728H146.181L154.837 87.6254L110.394 87.5963Z" fill="#FFFFFF"/>
</Svg>

  );
};

export default Icon;
