import { ChainId, WCRO, ERC20Token } from '@pancakeswap/sdk'
import { CCC, CCC_MAINET, PNDM_MAINET, USDC_CRONOS, USDT_CRONOS, WBTC_CRONOS, ETH_CRONOS } from './common'

export const cronosTokens = {
  wcro: WCRO[ChainId.CRONOS],
 // weth: WCRO[ChainId.CRONOS],
  usdt: USDT_CRONOS,
  usdc: USDC_CRONOS,
  wbtc: WBTC_CRONOS,
  eth:  ETH_CRONOS,
  ether: new ERC20Token(
    ChainId.CRONOS,
    '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
    18,
    'CRO',
    'CRO',
    'https://cronos.org/',
  ),
  cro: new ERC20Token(
    ChainId.CRONOS,
    '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
    18,
    'CRO',
    'CRO',
    'https://cronos.org/',
  ),
  ccc: CCC_MAINET,
  pndm: PNDM_MAINET,

  crolaunch:
  new ERC20Token(
    ChainId.CRONOS,
    '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
    18,
    'CRO',
    'CRO',
    'https://cronos.org/',
  ),
  
  moore: new ERC20Token(
    ChainId.CRONOS,
    '0x8d6EE0dD02443dE3E33E1128e853A407d5E7f5e8',
    18,
    'MOORE',
    'Moore Token',
    'https://cronoscats.club/',
  ),

}
