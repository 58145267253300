import BigNumber from 'bignumber.js'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import { getMemesaleCreditAddressContract } from 'utils/contractHelpers'

export const fetchPublicMemesaleData = async () => {
  try {
    const memesaleCreditAddressContract = getMemesaleCreditAddressContract()
    const ceiling = await memesaleCreditAddressContract.ceiling()
    return {
      ceiling: new BigNumber(ceiling.toString()).toJSON(),
    }
  } catch (error) {
    return {
      ceiling: BIG_ZERO.toJSON(),
    }
  }
}

export const fetchUserMemesaleCredit = async (account: string) => {
  try {
    const memesaleCreditAddressContract = getMemesaleCreditAddressContract()
    const credit = await memesaleCreditAddressContract.getUserCredit(account)
    return new BigNumber(credit.toString()).toJSON()
  } catch (error) {
    return BIG_ZERO.toJSON()
  }
}
