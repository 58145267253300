import poolsConfig from 'config/constants/pools'
import sousChefABI from 'config/abi/sousChef.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall, { multicallv3 } from 'utils/multicall'
import { getAddress, getMulticallAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import uniq from 'lodash/uniq'
import fromPairs from 'lodash/fromPairs'
import multiCallAbi from 'config/abi/Multicall.json'

// Pool 0, CCC / Cct is a different kind of contract (master chef)
// cro pools use the native cro token (wrapping ? unwrapping is done at the contract level)
const nonCroPools = poolsConfig.filter((pool) => pool.stakingToken.symbol !== 'CRO')
const croPools = poolsConfig.filter((pool) => pool.stakingToken.symbol === 'CRO')
const nonMasterPools = poolsConfig.filter((pool) => pool.sousId !== 0)
const multicallAddress = getMulticallAddress()

export const fetchPoolsAllowance = async (account) => {
  const calls = nonCroPools.map((pool) => ({
    address: pool.stakingToken.address,
    name: 'allowance',
    params: [account, getAddress(pool.contractAddress)],
  }))

  const allowances = await multicall(erc20ABI, calls)
  return fromPairs(nonCroPools.map((pool, index) => [pool.sousId, new BigNumber(allowances[index]).toJSON()]))
}

export const fetchUserBalances = async (account) => {
  // Non Cro pools
  const tokens = uniq(nonCroPools.map((pool) => pool.stakingToken.address))
  const tokenBalanceCalls = tokens.map((token) => ({
    abi: erc20ABI,
    address: token,
    name: 'balanceOf',
    params: [account],
  }))
  const croBalanceCall = {
    abi: multiCallAbi,
    address: multicallAddress,
    name: 'getEthBalance',
    params: [account],
  }
  const tokenCroBalancesRaw = await multicallv3({ calls: [...tokenBalanceCalls, croBalanceCall] })
  const croBalance = tokenCroBalancesRaw.pop()
  const tokenBalances = fromPairs(tokens.map((token, index) => [token, tokenCroBalancesRaw[index]]))

  const poolTokenBalances = fromPairs(
    nonCroPools
      .map((pool) => {
        if (!tokenBalances[pool.stakingToken.address]) return null
        return [pool.sousId, new BigNumber(tokenBalances[pool.stakingToken.address]).toJSON()]
      })
      .filter(Boolean),
  )

  // Cro pools
  const croBalanceJson = new BigNumber(croBalance.toString()).toJSON()
  const croBalances = fromPairs(croPools.map((pool) => [pool.sousId, croBalanceJson]))

  return { ...poolTokenBalances, ...croBalances }
}

export const fetchUserStakeBalances = async (account) => {
  const calls = nonMasterPools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'userInfo',
    params: [account],
  }))
  const userInfo = await multicall(sousChefABI, calls)
  return fromPairs(
    nonMasterPools.map((pool, index) => [pool.sousId, new BigNumber(userInfo[index].amount._hex).toJSON()]),
  )
}

export const fetchUserPendingRewards = async (account) => {
  const calls = nonMasterPools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'pendingReward',
    params: [account],
  }))
  const res = await multicall(sousChefABI, calls)
  return fromPairs(nonMasterPools.map((pool, index) => [pool.sousId, new BigNumber(res[index]).toJSON()]))
}
